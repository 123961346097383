import React from 'react';
import { navigate } from 'gatsby';
import { Box } from 'grommet';
import { useMutation } from '@apollo/client';
import { paymentLink } from 'services/hasura';

export default function PaymentLink({ order }: { order: namespace.Order }) {
  const [getLink, { data, loading, error }] = useMutation(paymentLink, {
    fetchPolicy: 'no-cache'
  });

  React.useEffect(() => {
    if (loading) {
      return;
    }
    if (data && !error) {
      navigate(data.cardPayment.authorization_url);
    }
  }, [loading, data, error]);

  React.useEffect(() => {
    if (loading) return;
    try {
      if (order.uid) {
        getLink({ variables: { uid: order.uid } });
      }
    } catch (error) {
      console.log(error);
      navigate(`/booking/choose-payment?id=${order.id}&error=${error?.message}`);
    }
  }, [getLink, order]);
  return <Box />;
}
